<template>
  <div
    class="cosmos-filter-container slider-container"
    ref="sliderContainer"
    @mousedown="startDrag"
    @mousemove="onDrag"
    @mouseup="endDrag"
    @mouseleave="endDrag"
  >
    <v-slide-group show-arrows>
      <v-slide-item
        v-for="date in dates"
        :key="date.all"
        v-slot="{ active }"
        v-model="sliderValue"
        class="my-2"
      >
        <div
          class="cosmos-filter-item not-selectable"
          :input-value="active"
          :class="{
            'cosmos-filter-item-active': date.all === value
          }"
          @click="selectDate(date)"
        >
          <span
            :class="{
              'font-head': date.all !== value,
              'font-head-active': date.all === value
            }"
            class="fonte"
          >{{ date.weekday }}</span>
          <span class="fonte-bold">{{ date.day }}</span>
          <span
            :class="{
              'font-foot': date.all !== value,
              'font-foot-active': date.all === value
            }"
            class="fonte"
          >{{ date.monthName }}</span>
        </div>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      sliderValue: 30,
      isDragging: false,
      startX: 0,
      scrollLeft: 0
    };
  },
  methods: {
    selectDate(date) {
      this.$emit("input", `${date.day}/${date.month}/${date.year}`);
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.pageX - this.$refs.sliderContainer.$el.offsetLeft;
      this.scrollLeft = this.$refs.sliderContainer.$el.scrollLeft;
    },
    onDrag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.sliderContainer.$el.offsetLeft;
      const walk = (x - this.startX) * 2; // Ajuste a velocidade de deslocamento se necessário
      this.$refs.sliderContainer.$el.scrollLeft = this.scrollLeft - walk;
    },
    endDrag() {
      this.isDragging = false;
    }
  },
  computed: {
    dates() {
      let range = [];

      let today = this.$moment();
      today = {
        all: today.format("DD/MM/YYYY"),
        day: today.format("DD"),
        month: today.format("MM"),
        year: today.format("YYYY"),
        weekday: "Hoje",
        monthName: today.format("MMMM").substring(0, 3)
      };
      range.push(today);

      let yesterday = this.$moment().subtract(1, "days");
      yesterday = {
        all: yesterday.format("DD/MM/YYYY"),
        day: yesterday.format("DD"),
        month: yesterday.format("MM"),
        year: yesterday.format("YYYY"),
        weekday: "Ontem",
        monthName: yesterday.format("MMMM").substring(0, 3)
      };
      range.push(yesterday);

      for (let i = 2; i < 4; i++) {
        const date = this.$moment().subtract(i, "days");
        range.push({
          all: date.format("DD/MM/YYYY"),
          day: date.format("DD"),
          month: date.format("MM"),
          year: date.format("YYYY"),
          weekday: date.format("dddd").substring(0, 3), // Nome do dia da semana
          monthName: date.format("MMMM").substring(0, 3) // Nome do mês
        });
      }
      this.selectDate(today);
      return range;
    }
  }
};
</script>

<style lang="scss">
.cosmos-filter-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Certifique-se de que a área de arrasto não saia dos limites */
  user-select: none; /* Evita a seleção de texto ao arrastar */
}
.slider-container {
  display: flex;
  overflow-x: auto; /* Permitir rolagem horizontal */
  scroll-behavior: smooth; /* Suavizar a rolagem */
  -webkit-overflow-scrolling: touch; /* Melhorar o comportamento em dispositivos móveis */
  cursor: grab; /* Indicar que o container pode ser arrastado */
}
.slider-container:active {
  cursor: grabbing; /* Indicar que o container está sendo arrastado */
}
.cosmos-filter-item {
  display: flex;
  margin-right: 4px;
  margin-left: 4px;
  min-width: 40px;
  border-radius: 6px;
  border: 1px solid #cbc7c7;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 10pt;
  padding: 6px;
  font-weight: bold;
  font-size: 16pt;
  color: #333;
}
.cosmos-filter-item:hover {
  scale: 1.1;
  transition: 0.3s;
}
.font-head {
  font-size: 10pt;
  color: grey;
  margin-bottom: -6px;
}
.font-foot {
  font-size: 10pt;
  color: grey;
  margin-top: -10px;
}
.cosmos-filter-item-active {
  background: #4570b6 !important;
  color: #fff !important;
  font-size: 16pt;
  scale: 1.2 !important;
}
.font-head-active {
  font-size: 10pt;
  color: #fff !important;
  margin-bottom: -6px;
}
.font-foot-active {
  font-size: 10pt;
  color: #fff !important;
  margin-top: -10px;
}
</style>
