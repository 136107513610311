var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"sliderContainer",staticClass:"cosmos-filter-container slider-container",on:{"mousedown":_vm.startDrag,"mousemove":_vm.onDrag,"mouseup":_vm.endDrag,"mouseleave":_vm.endDrag}},[_c('v-slide-group',{attrs:{"show-arrows":""}},_vm._l((_vm.dates),function(date){return _c('v-slide-item',{key:date.all,staticClass:"my-2",scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('div',{staticClass:"cosmos-filter-item not-selectable",class:{
          'cosmos-filter-item-active': date.all === _vm.value
        },attrs:{"input-value":active},on:{"click":function($event){return _vm.selectDate(date)}}},[_c('span',{staticClass:"fonte",class:{
            'font-head': date.all !== _vm.value,
            'font-head-active': date.all === _vm.value
          }},[_vm._v(_vm._s(date.weekday))]),_c('span',{staticClass:"fonte-bold"},[_vm._v(_vm._s(date.day))]),_c('span',{staticClass:"fonte",class:{
            'font-foot': date.all !== _vm.value,
            'font-foot-active': date.all === _vm.value
          }},[_vm._v(_vm._s(date.monthName))])])]}}],null,true),model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }