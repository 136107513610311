import http from "@/apps/shared/baseService/config";
import { EventBus } from "@/main.js";
import router from "@/router";

const base = {
  rota: "checkout",
  nome: "Checkout"
};

export const service = {
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  list_plans: data => http.post(`/client/${base.rota}/list-plans`, data),
  view_plan: data => http.post(`/client/${base.rota}/view-plan`, data),
  checkout: data => http.post(`/client/${base.rota}/checkout`, data),
  check_transaction_payment: data =>
    http.post(`/client/${base.rota}/check-transaction-payment`, data)
};

const module = {
  actions: {
    view_plan: ({ commit, dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .view_plan({ identifier: payload.plan_slug })
        .then(res => {
          const plan = res.data;
          dispatch("disableLoading");
          let trial = "";
          if (payload.trial === "true") {
            trial = plan.trial || 7;
          } else {
            trial = false;
          }
          commit("set_checkout", {
            ...getters.get_checkout,
            plan,
            trial
          });
        })
        .catch(e => {
          dispatch("disableLoading");
          console.log("e", e);
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    //finaliza a compra
    checkout: async ({ commit, dispatch, getters }) => {
      try {
        dispatch("enableLoading");
        const checkout = await service.checkout({
          ...getters.get_checkout,
          // payment_method: getters.get_checkout.method
        });
        dispatch("disableLoading");
        router.push(`/criar-empresa?token=${checkout.data.token}`);
        // EventBus.$emit("checkout-success", checkout.data);
        return checkout;
      } catch (error) {
        EventBus.$emit("checkout-error", error.response.data.message);
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: error.response.data.message,
          timeout: 6000
        });
        return false;
      }
    },
    //verifica o resultado da compra
    check_transaction_payment: async ({ commit, dispatch }, payload) => {
      try {
        const status_order = await service.check_transaction_payment(payload);
        EventBus.$emit("modal-order-status", status_order);
        return status_order;
      } catch (error) {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: error.response.data.message,
          timeout: 6000
        });
        return false;
      }
    }
  },
  state: {
    // checkout: {
    //   method: "credit-card",
    //   card: {
    //     number: ""
    //   },
    //   plan: {},
    //   payer: {
    //     address: {}
    //   }
    // },
    checkout: {
      method: "credit-card",
      card: {
        number: ""
      },
      plan: {},
      payer: {
        name: "",
        email: "",
        phone_prefix: "",
        phone: "",
        cpf_cnpj: "",
        address: {}
      }
    }
  },
  mutations: {
    set_checkout: (state, payload) => (state.checkout = payload)
  },
  getters: {
    get_checkout: state => state.checkout
  }
};

const routes = [
  {
    path: `/comprar-plano/:plan_slug`,
    component: () => import("./views/MainPublic.vue"),
    name: "Checkout Public",
    props: true
  },
  {
    path: `/comparativo`,
    component: () => import("./views/PlanComparative.vue"),
    name: "Comparativo de Planos"
  }
];

export default {
  service,
  module,
  routes
};
