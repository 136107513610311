import axios from "axios";
// const host = "http://192.168.1.72:3002";
const host = "https://stonepro.cosmoscode.com.br/p/";
// const host = process.env.VUE_APP_URL_API;

let http = axios.create({
  baseURL: host,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      localStorage.token ? JSON.parse(localStorage.token) : ""
    }`
  }
});

http.interceptors.request.use(
  function(config) {
    config.headers.Authorization = `Bearer ${
      localStorage.token ? JSON.parse(localStorage.token) : ""
    }`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default http;
