import http from "@/apps/shared/baseService/config";

const base = {
  rota: "custom-key",
  nome: "Chaves Personalizadas"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/archive`, data)
};

const module = {
  actions: {
    add_custom_key_in_indexeddb: ({ getters }, payload) => {
      const dbname = getters.getLoggedUser.tenant[0]._id;
      const request = window.indexedDB.open(dbname, 1);
      request.onupgradeneeded = function(event) {
        const db = event.target.result;
        // Crie uma loja de objetos (object store) chamada "minhaLoja".
        // const store = db.createObjectStore("customs", { keyPath: "_id" });
        db.createObjectStore("customs", { keyPath: "_id" });
        // Crie um índice chamado "por_nome" na loja de objetos "minhaLoja".
        // store.createIndex("por_nome", "nome", { unique: false });
      };
      request.onsuccess = function(event) {
        const db = event.target.result;
        const tx = db.transaction("customs", "readwrite");
        const store = tx.objectStore("customs"); //coleção
        if (Array.isArray(payload)) {
          payload.forEach(item => {
            store.put(item);
          });
        }
        tx.oncomplete = function() {
          db.close();
        };
      };
      request.onerror = function(e) {
        console.log("Erro ao abrir o IndexedDB", e);
      };
    },
    remove_custom_key_from_indexeddb: ({ getters }, payload) => {
      const DB = window.indexedDB.open(getters.getLoggedUser.tenant[0]._id, {
        keyPath: "_id"
      });
      DB.onsuccess = function(event) {
        const db = event.target.result;
        const tx = db.transaction("customs", "readwrite");
        const store = tx.objectStore("customs"); //coleção
        store.delete(payload);
        tx.oncomplete = function() {
          db.close();
        };
      };
    },
    search_custom_key_in_indexeddb: ({ getters }, payload) => {
      const DB = window.indexedDB.open(getters.getLoggedUser.tenant[0]._id, {
        keyPath: "_id"
      });
      DB.onsuccess = function(event) {
        const db = event.target.result;
        const tx = db.transaction("customs", "readwrite");
        const store = tx.objectStore("customs"); //coleção
        const request = store.get(payload);
        request.onsuccess = function() {
          db.close();
        };
      };
    },
    search_by_input_in_indexedDB: ({ commit, getters }, payload) => {
      return new Promise((resolve, reject) => {
        const DB = window.indexedDB.open(getters.getLoggedUser.tenant[0]._id, {
          keyPath: "_id"
        });

        DB.onsuccess = function(event) {
          const db = event.target.result;
          const tx = db.transaction("customs", "readwrite");
          const store = tx.objectStore("customs"); // coleção
          const index = store.index("custom_value");
          const request = index.getAll(payload);

          request.onsuccess = function() {
            commit("set_customs", request.result);
            db.close();
            resolve(request.result); // Resolvendo a Promise com o resultado
          };

          request.onerror = function() {
            db.close();
            reject(new Error("Erro ao buscar dados no IndexedDB"));
          };
        };

        DB.onerror = function() {
          reject(new Error("Erro ao abrir o IndexedDB"));
        };
      });
    },

    listar_customs: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_customs_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_customs", res.data);
          res.data.docs.forEach(item => {
            dispatch("add_in_indexeddb", item);
          });
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_custom: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_custom, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_custom", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_customs");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_custom: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_custom, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_custom", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_customs");
          commit("set_modal_view_custom", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_custom: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_customs");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_customs: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_customs_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_customs");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    }
  },
  state: {
    customs: {
      docs: []
    }
  },
  mutations: {
    set_customs: (state, payload) => (state.customs = payload),
    set_customs_marcados: (state, payload) =>
      (state.customs_marcados = payload),
    set_modal_view_custom: (state, payload) =>
      (state.modal_view_custom = payload)
  },
  getters: {
    get_customs: state => state.customs,
    get_customs_filtros: state => state.customs_filtros
  }
};

const routes = [
  // {
  //   path: `/${base.rota}`,
  //   component: () => import("./views/Listar.vue"),
  //   name: base.nome
  // }
];

export default {
  service,
  module,
  routes
};
