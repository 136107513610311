export const getters = {
  getLoggedUser: state => state.loggedUser,
  get_permission_user: state => state.loggedUser.permissao.permission,
  get_permission_plan: state => state.loggedUser.subscription.plan_id.features,
  getToken: state => state.token,
  getSponsor: state => state.sponsor,
  getModalForgotPassword: state => state.modalForgotPassword
};

export default {};
