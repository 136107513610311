import store from "@/store";

function verifyIfUserIsAuthenticated(to, from, next) {
  if (store.getters.getLoggedUser) {
    return next();
  }
  return next("/");
}

export default verifyIfUserIsAuthenticated;
