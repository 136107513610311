export default function middleware(guards) {
  const guardsArray = Array.isArray(guards) ? guards : [guards];

  return (to, from, next) => {
    return middlewareSequence(guardsArray, to, from, next);
  };
}

function middlewareSequence(guards, to, from, next) {
  const guardsLeft = guards.slice(0);
  const nextGuard = guardsLeft.shift();

  if (nextGuard === undefined) {
    next();
    return;
  }

  nextGuard(to, from, nextArg => {
    if (nextArg === undefined) {
      middlewareSequence(guardsLeft, to, from, next);
      return;
    }

    next(nextArg);
  });
}
