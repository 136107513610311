import http from "@/apps/shared/baseService/config";

const base = {
  rota: "permissions",
  nome: "Permissões"
};

export const service = {
  criar: data => http.post(`/admin/${base.rota}/create`, data),
  listar: data => http.post(`/admin/${base.rota}/list`, data),
  atualizar: data => http.post(`/admin/${base.rota}/update`, data),
  excluir: data => http.post(`/admin/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/admin/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    list_permissions: ({ commit, dispatch, getters }) => {
      if (localStorage.getItem("permissions")) {
        commit(
          "set_permissions",
          JSON.parse(localStorage.getItem("permissions"))
        );
      } else {
        dispatch("enableLoading");
      }
      service
        .listar(getters.get_permissions_filtros)
        .then(res => {
          dispatch("disableLoading");
          localStorage.setItem("permissions", JSON.stringify(res.data));
          commit("set_permissions", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_permission: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_permission, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_permission", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("list_permissions");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_permission: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_permission, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_permission", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("list_permissions");
          commit("set_modal_view_permission", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_permission: ({ dispatch }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("list_permissions");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_permission: ({ commit }, payload) => {
      if (payload) {
        commit("set_permission", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_permission", true);
    },
    fecha_modal_view_permission: ({ commit }) => {
      commit("set_permission", {});
      commit("setLinks", []);
      commit("set_modal_view_permission", false);
    }
  },
  state: {
    permission: {},
    permissions: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    permissions_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_permission: false
  },
  mutations: {
    set_permission: (state, payload) => (state.permission = payload),
    set_permissions: (state, payload) => (state.permissions = payload),
    set_modal_view_permission: (state, payload) =>
      (state.modal_view_permission = payload)
  },
  getters: {
    get_permission: state => state.permission,
    get_permissions: state => state.permissions,
    get_modal_view_permission: state => state.modal_view_permission,
    get_permissions_filtros: state => state.permissions_filtros
  }
};

const routes = [
  {
    path: `/permissoes`,
    component: () => import("./views/Main.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
