import Vue from "vue";
import store from "@/store";
import App from "./App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import mask from "vue-the-mask";
import moment from "moment";
import "moment/locale/pt-br";
import "@/apps/shared";
import { db, storage, app, analytics } from "./apps/shared/firebase";
import run from "./apps/shared/Onoff";
import Helper from "./helper";
import VueHtml2pdf from "vue-html2pdf";
import VuejsClipper from "vuejs-clipper";
import VueCropper from "vue-cropper";
import vueVimeoPlayer from "vue-vimeo-player";
import CosmosFilterDate from "@/apps/shared/components/cosmos-filter.vue";
import "driver.js/dist/driver.css";

// import "./registerServiceWorker";
Vue.use(vueVimeoPlayer);

Vue.use(VueCropper);

// import "vue-roller/dist/style.css";

export const EventBus = new Vue();

Vue.component("cosmos-filter-date", CosmosFilterDate);

// Vue.use(VueRoller);
Vue.use(VueHtml2pdf);
Vue.use(mask);
Vue.use(VuejsClipper);
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;

const theme = {
  primary: "#4570b6",
  secondary: "#fbae1d",
  third: "#47cf72",
  fourth: "#325286",
  light: true,
  dark: false,
  icon_drawer: "#333",
  icon_color: "#e1673c",
  app_bar: "#444",
  background: "#f5f5f5",
  grey: "#f2f2f2",
  drawer: "#1173ff",
  logo: "https://upload.wikimedia.org/wikipedia/commons/d/d4/ROCKET_logo.svg",
  token_img: ""
};

Vue.prototype.$theme = theme;
Vue.prototype.$db = db;
Vue.prototype.$app = app;
Vue.prototype.$storage = storage;
Vue.prototype.$analytics = analytics;
Vue.prototype.$helper = new Helper();
Vue.prototype.$run = run;


new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
